// BASE
@import "base/normalize";
// @import "base/typography";
@import "base/general";

// HELPERS
@import "helpers/variables";
@import "helpers/utilities";

// COMPONENTS
@import "components/header";
@import "components/navigation";
@import "components/not-found";
@import "components/sitemap";
@import "components/youtube";

// THEMES
@import "themes/mui";
@import "themes/highcontrast";

html,
body {
	font-family: "Montserrat", sans-serif;
	color: #222;
	height: 100%;
	margin: 0;
}

img {
	height: auto;
}

.header__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	max-width: 1710px;
	margin: 0 auto;
	padding: 0 32px;
}

.header__column {
	display: flex;
	align-items: center;
}

ul.header__navigation-list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

a.header__navigation-link {
	display: flex;
	align-items: center;
	height: 124px;
	padding: 0 32px;
	font-size: 20px;
	color: #222;
	text-decoration: none;
}

.header__wcag-controls {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
}

section.banner {
	background: transparent linear-gradient(342deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
		no-repeat padding-box;
	color: #fff;
}

.banner__wrapper {
	min-height: 500px;
	display: flex;
	align-items: center;
	max-width: 1710px;
	margin: 0 auto;
	padding: 0 32px;
}

.banner__heading h2 {
	font-size: 86px;
	font-weight: 500;
	width: 70%;
	color: #fff;
}

.h3-heading {
	font-size: 48px;
	font-weight: 500;
	margin: 0 0 32px;
}

.administration__wrapper {
	display: flex;
	gap: 64px;
	max-width: 1710px;
	margin: 0 auto;
	padding: 124px 32px 84px;
}

.pu-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	background: transparent;
	padding: 16px 32px;
	border-radius: 32px;
	font-weight: 400;
	text-transform: uppercase;
	border: 1px solid transparent;
	transition: all 125ms ease-in-out;
}

.pu-btn:hover {
	cursor: pointer;
}

.pu-btn--primary {
	background: #d952df;
	border: 1px solid #d952df;
	color: #fff;
	font-size: 24px;
}

.pu-btn--tertiary {
	background: transparent linear-gradient(96deg, #0d309d 0%, #1a73de 100%) 0% 0% no-repeat
		padding-box;
	color: #fff;
}

p.section__text {
	font-size: 20px;
	font-weight: 400;
	line-height: 34px;
}

.demo {
	overflow: hidden;
}

.demo__wrapper {
	position: relative;
	max-width: 1710px;
	min-height: 392px;
	margin: 0 auto;
	padding: 0 32px;
	display: flex;
	align-items: center;
}

.meet__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.pu-btn--secondary {
	border: 1px solid #1a73de;
	color: #1a73de;
}

footer.footer {
	background: #0c2b97 0% 0% no-repeat padding-box;
	color: #fff;
}

.footer__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
	display: flex;
	align-items: center;
	gap: 64px;
}

.footer__info {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-right: 124px;
	margin-right: 124px;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}

section.lorem {
	background: transparent linear-gradient(254deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
		no-repeat padding-box;
}

.lorem__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
}

.blue {
	background: transparent linear-gradient(254deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
		no-repeat padding-box;
	color: #fff;
}

section.meet {
	background: #fafafa;
}

section.change {
	background: #fafafa;
}

.change__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
}

.relations__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
	align-items: center;
}

.white {
	color: #fff;
}

.banner__heading p {
	font-size: 30px;
	font-weight: 500;
	margin: 0;
}

.banner__heading h2 {
	line-height: 124px;
	margin: 0 0 16px;
	width: 100%;
}

.pink {
	color: #d952df;
}

ul.section__list {
	font-size: 20px;
}

li.section__list-item {
	margin-bottom: 32px;
}

.banner__image-wrapper {
	position: relative;
}

.banner__image-wrapper img {
	max-width: 550px;
	max-height: 500px;
	position: relative;
	top: 64px;
}

.administration__image img {
	max-height: 160px;
}

.administration__image {
	text-align: center;
}

.administration p.section__text {
	min-height: 100px;
}

.demo__image img {
	max-height: 380px;
}

.demo__background img {
	max-height: 392px;
}

.demo__background {
	position: absolute;
	top: 50%;
	right: -10%;
	transform: translateY(-50%);
}

.demo__image {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}

.meet__image img {
	width: 100%;
}

.lorem__image img {
	max-height: 422px;
}

.footer__logo {
	max-height: 40px;
	align-self: flex-start;
}

.footer__contact-icon {
	max-height: 44px;
}

footer.footer a {
	color: #fff;
	text-decoration: none;
}

.footer__contact {
	display: flex;
	align-items: center;
	gap: 16px;
}

.footer__contact a {
	display: flex;
	align-items: center;
	gap: 16px;
}

.footer__top {
	margin-bottom: 32px;
}

.footer__bottom {
	display: flex;
	gap: 64px;
}

.header {
	overflow: hidden;
}

.header__logo {
	margin-right: 64px;
}

.demo p.section__text {
	margin-bottom: 64px;
	font-size: 30px;
}

.custom-list {
	font-size: 20px;
	list-style: none;
	padding-left: 0;
	margin: 48px 0;
}

.custom-list__item {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	font-size: 1.2rem;
}

.custom-list__icon {
	background-image: url("https://przyjazne-deklaracje.pl/resources/images/icons/general/files/check.svg");
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-right: 16px;
}

/* .custom-list__item:focus,
.custom-list__item:hover {
  outline: 2px solid #1a73de;
} */

.custom-list__item {
	color: #222;
}

/* .custom-list__item:focus-within {
  background-color: #f0f0f0;
} */

.digital__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.portal-informacyjny__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.panel-administracyjny__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.portal-urzednika__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.portal-urzednika__image img {
	width: 100%;
}

.panel-administracyjny__image img {
	width: 100%;
}

.portal-informacyjny__image img {
	width: 100%;
}

.digital__image img {
	width: 100%;
}

.banner.small .banner__wrapper {
	min-height: 240px;
}

ul {
	font-size: 20px;
	padding-left: 20px;
	font-weight: 400;
}

li {
	margin-bottom: 24px;
}

.portal-mieszkanca__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 64px 32px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
}

.portal-mieszkanca__image img {
	width: 100%;
}

section.portal-mieszkanca {
	background: #fafafa;
}

section.panel-administracyjny {
	background: #fafafa;
}

.contact__block {
	border-radius: 20px;
	box-shadow: 0px 3px 50px #2222220d;
	padding: 32px;
	width: 100%;
}

.contact__wrapper {
	display: flex;
	max-width: 1710px;
	margin: 0 auto;
	padding: 48px 32px;
	gap: 32px;
}

.contact__map iframe {
	border-radius: 20px;
}

h2.contact__heading {
	font-size: 30px;
	font-weight: 400;
	margin: 0 0 32px;
	line-height: 44px;
}

section.contact p {
	font-size: 20px;
	font-weight: 400;
}

.contact__list {
	display: flex;
	margin-bottom: 24px;
}

.contact__image {
	padding-top: 12px;
	padding-right: 16px;
}

.contact__image img {
	max-height: 40px;
}

.contact__map {
	margin-top: 32px;
}

a {
	color: #1a73de;
	text-decoration: none;
	cursor: pointer;
}

.contact a {
	font-weight: 400;
}

li.header__navigation-item {
	margin-bottom: 0;
}

a.header__navigation-link:hover {
	background: #fafafa;
}

.rozwiazania__wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
	padding: 32px;
	max-width: 1710px;
	margin: 0 auto;
}

.zespol__wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 64px;
	padding: 32px;
	max-width: 1710px;
	margin: 0 auto;
}

section.foto-o4b > img {
	width: 100%;
}

.foto-o4b__footer {
	background: rgba(34, 34, 34, 0.7);
	position: absolute;
	bottom: 0px;
	width: 100%;
}

.foto-o4b__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 166px;
	gap: 124px;
}

section.foto-o4b {
	position: relative;
	top: 4px;
}

footer.footer {
	position: relative;
	z-index: 1;
}

.section__text.bold {
	font-size: 24px;
	font-weight: 500;
}

.zaufanie__block {
	height: 100%;
}

.zaufanie__wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	padding: 48px 32px;
	max-width: 1710px;
	margin: 0 auto;
	gap: 62px;
}

.zaufanie__image {
	height: 90px;
}

.zaufanie__title {
	font-size: 30px;
	font-weight: 500;
	height: 54px;
	margin: 0;
}

.zaufanie__description {
	font-weight: 400;
	line-height: 22px;
	margin: 0;
}

.zaufanie__image img {
	height: 64px;
}

section.zaufanie {
	background: #fafafa;
}

section.banner.small {
	position: relative;
	background: transparent linear-gradient(326deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
		no-repeat padding-box;
	overflow: hidden;
}

.banner.small .banner__decoration__one {
	position: absolute;
	left: 16px;
	bottom: 16px;
}

.banner.small .banner__decoration__two {
	position: absolute;
	top: 0;
	left: 30%;
}

.banner.small .banner__decoration__three {
	position: absolute;
	top: 64px;
	left: 40%;
}

.banner.small .banner__decoration__four {
	position: absolute;
	top: 0;
	right: 20%;
}

.banner.small .banner__decoration__five {
	position: absolute;
	right: 10%;
	bottom: 0;
}

.blue-2 {
	background: transparent linear-gradient(103deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
		no-repeat padding-box;
	color: #fff;
}

.kompleksowa {
	overflow: hidden;
}

.kompleksowa__wrapper {
	position: relative;
	max-width: 1710px;
	min-height: 392px;
	margin: 0 auto;
	padding: 0 32px;
	display: flex;
	align-items: center;
}

.kompleksowa__image img {
	max-height: 380px;
	margin-top: 92px;
}

.kompleksowa__background img {
	max-height: 392px;
}

.kompleksowa__background {
	position: absolute;
	top: 50%;
	left: -10%;
	transform: translateY(-50%);
}

.kompleksowa__image {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}

.kompleksowa__wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.kompleksowa__element {
	font-size: 24px;
	margin-bottom: 32px;
}

.kompleksowa__element img {
	margin-right: 24px;
}

.kompleksowa__list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
}

.banner.image .banner__wrapper {
	position: relative;
}

.banner.image .banner__decoration__one {
	position: absolute;
	bottom: 16px;
	left: -64px;
}

.banner.image .banner__decoration__two {
	position: absolute;
	top: 10%;
	left: -64px;
}

.banner.image .banner__decoration__three {
	position: absolute;
	top: 0;
	right: 20%;
}

.banner.image .banner__decoration__five {
	position: absolute;
	bottom: -32px;
	right: 0;
}

.banner.small h2.banner__heading {
	font-size: 86px;
	font-weight: 500;
}

.change h3.h3-heading {
	margin-top: 32px;
}

.categories__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 48px 32px 16px;
	display: flex;
	align-items: center;
}

.categories__container {
	display: flex;
	align-items: center;
}

.categories__title {
	color: #1a73de;
	margin-right: 16px;
	padding-right: 16px;
	border-right: 1px solid #e1e1e1;
}

.categories__heading {
	margin-right: 16px;
}

.categories__title:last-child {
	border-right: 0;
}

.tags__title {
	color: #1a73de;
	border: 1px solid #1a73de;
	border-radius: 24px;
	padding: 12px 16px;
	margin-right: 16px;
}

.tags__container {
	display: flex;
	align-items: center;
}

.tags__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
	display: flex;
	align-items: center;
}

.tags__heading {
	margin-right: 16px;
}

.article__article {
	border-radius: 20px;
	box-shadow: 0px 3px 50px #2222220d;
	padding: 32px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.artitle__title {
	font-size: 48px;
	margin-bottom: 32px;
}

.article__tag {
	color: #1a73de;
	border: 1px solid #1a73de;
	border-radius: 24px;
	padding: 12px 16px;
	margin-right: 16px;
}

.article__author {
	color: #555555;
	margin: 24px 0 8px;
}

p.article__description {
	font-size: 20px;
	line-height: 34px;
}

.article__wrapper {
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	gap: 24px;
}

section.article {
	margin-bottom: 64px;
}

.aktualnosci__wrapper {
	max-width: 1710px;
	margin: 0 auto;
}

.aktualnosci__author {
	margin-bottom: 24px;
	color: #555555;
}

.aktualnosci__tag {
	color: #1a73de;
	border: 1px solid #1a73de;
	border-radius: 24px;
	padding: 12px 16px;
	margin-right: 16px;
}

.aktualnosci__aktualnosci {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: 64px;
	margin-bottom: 64px;
	border-bottom: 1px solid #e1e1e1;
}

p.aktualnosci__description {
	font-size: 20px;
	line-height: 34px;
}

.wrapper-temporary {
	display: flex;
	max-width: 1710px;
	margin: 0 auto;
	padding: 32px;
}

section.aktualnosci {
	width: 70%;
	margin-right: 32px;
	padding-right: 32px;
	border-right: 1px solid #e1e1e1;
}

section.popular {
	width: 30%;
}

.popular .artitle__title {
	font-size: 30px;
}

.popular p.aktualnosci__description {
	font-size: 16px;
}

.popular__title {
	font-size: 20px;
	margin-bottom: 32px;
}

.popular .aktualnosci__aktualnosci:last-child {
	border-bottom: 0;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.contact-form input {
	min-height: 34px;
	border: 0;
	border-bottom: 1px solid #e1e1e1;
	padding: 4px;
	width: 100%;
}

.contact-form textarea {
	padding: 24px;
	border: 1px solid #e1e1e1;
	border-radius: 20px;
	width: calc(100% - 48px);
	min-height: 148px;
}

.contact-form > div {
	margin-bottom: 32px;
}

.contact-form {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	margin-top: 48px;
}

.contact-form__office,
.contact-form__message,
.contact-form__consent,
.contact-form__captcha,
.contact-form__action {
	grid-column: 1 / -1;
}

.contact-form__name,
.contact-form__email,
button {
	grid-column: span 1;
}

.contact-form__name,
.contact-form__email,
.contact-form__captcha {
	display: inline-block;
}

@media (min-width: 768px) {
	.contact-form {
		grid-template-columns: repeat(2, 1fr);
	}

	.contact-form__name,
	.contact-form__email,
	button {
		grid-column: span 1;
	}
}

.contact-form__consent input {
	width: 20px;
	height: 20px;
	margin-right: 11px;
	position: relative;
	top: -6px;
}

.contact-form__consent {
	display: flex;
	align-items: flex-start;
	line-height: 22px;
}

.contact-form__message {
	margin-bottom: 0 !important;
}

.contact-form__consent {
	margin-bottom: 0 !important;
}

.contact-form__action {
	display: flex;
	justify-content: flex-end;
}

.contact-form__captcha {
	margin-bottom: 16px !important;
}

.contact-form__consent label {
	cursor: pointer;
}

.contact-form__error {
	display: block;
	margin-top: 8px;
	color: red;
}

ul.pu-list {
	list-style-type: none;
	padding-left: 0;
	margin-top: -16px;
}

ul.pu-list li {
	margin-bottom: 0;
}

ul.pu-list li::before {
	content: "•";
	color: blue;
	font-size: 2.5em;
	margin-right: 10px;
	position: relative;
	top: 8px;
}

.relations__image {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 16px;
}

.relations__image img {
	max-height: 100px;
	height: 100%;
	width: auto;
	object-fit: contain;
}

@media (max-width: 1366px) {
	.demo.blue {
		background: transparent linear-gradient(214deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
			no-repeat padding-box;
	}
	.demo__background {
		display: none;
	}
	.banner__heading h2 {
		font-size: 48px;
		line-height: 64px;
	}
	.banner__heading p {
		font-size: 20px;
	}
	.banner__wrapper {
		min-height: 300px;
	}
	.banner__image-wrapper img {
		width: 100%;
	}
	.h3-heading {
		font-size: 32px;
	}
	p.section__text {
		font-size: 16px;
	}
	.demo p.section__text {
		font-size: 16px;
		margin-bottom: 32px;
		font-weight: 500;
	}
	.pu-btn--primary {
		font-size: 16px;
	}
	.custom-list__item {
		font-size: 16px;
	}
	.lorem__image img {
		width: 100%;
	}
	.section__text.bold {
		font-size: 20px;
	}
	.footer__info {
		padding-right: 32px;
		margin-right: 32px;
	}
	ul.pu-list {
		font-size: 16px;
	}
}

@media (max-width: 1024px) {
	.banner.small h2.banner__heading {
		font-size: 48px;
	}
	.relations__wrapper {
		grid-template-columns: 1fr;
		gap: 16px;
	}
	.contact__wrapper {
		flex-direction: column;
	}
	.zaufanie__wrapper {
		grid-template-columns: 1fr 1fr;
		gap: 24px;
	}
	.zaufanie__image {
		padding-top: 32px;
	}
}

@media (max-width: 860px) {
	.contact__wrapper {
		padding: 0;
	}
	.banner.small h2.banner__heading {
		font-size: 38px;
		line-height: 48px;
	}
	ul.custom-list {
		margin: 24px 0 32px;
	}
	.banner__heading h2 {
		font-size: 38px;
		line-height: 48px;
	}
	.banner__heading p {
		font-size: 22px;
	}
	.h3-heading {
		font-size: 28px;
	}
	.demo__wrapper .section__block:first-child {
		z-index: 1;
	}
	.banner__image-wrapper {
		display: none;
	}
	.banner.image .banner__wrapper {
		min-height: 300px;
	}
	.administration__wrapper {
		flex-direction: column;
		padding: 64px 32px;
	}
	.meet__wrapper {
		grid-template-columns: 1fr;
	}
	.lorem__wrapper {
		grid-template-columns: 1fr;
	}
	.change__wrapper {
		grid-template-columns: 1fr;
		gap: 0;
	}
	.demo__image {
		display: none;
	}
	.footer__wrapper {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		padding: 32px 32px 64px;
	}
	.footer__info {
		padding: 0 0 32px 0;
		margin: 32px 0 32px 0;
		border: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.25);
		width: 100%;
		color: #9daad5;
	}
	.digital__wrapper {
		grid-template-columns: 1fr;
	}
	.portal-informacyjny__wrapper {
		grid-template-columns: 1fr;
	}
	.portal-mieszkanca__wrapper {
		grid-template-columns: 1fr;
	}
	.portal-mieszkanca__wrapper .section__block:first-child {
		order: 1;
	}
	.portal-urzednika__wrapper {
		grid-template-columns: 1fr;
	}
	.portal-urzednika__wrapper .section__block:first-child {
		order: 1;
	}
	.panel-administracyjny__wrapper {
		grid-template-columns: 1fr;
	}
	.rozwiazania__wrapper {
		grid-template-columns: 1fr;
		gap: 0;
	}
	.zespol__wrapper {
		grid-template-columns: 1fr;
		gap: 0;
	}
	.foto-o4b__wrapper {
		position: relative;
		flex-direction: column;
		gap: 32px;
		height: auto;
		padding: 32px;
		z-index: 1;
	}
	.zaufanie__wrapper {
		grid-template-columns: 1fr;
	}
	.kompleksowa__background {
		display: none;
	}
	.kompleksowa__image {
		position: static;
		transform: none;
	}
	.kompleksowa__wrapper {
		grid-template-columns: 1fr;
		padding: 64px 32px;
	}
	.kompleksowa__wrapper .section__block:first-child {
		order: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.kompleksowa__image img {
		max-height: 240px;
		margin-top: 32px;
	}
	.kompleksowa__list {
		grid-template-columns: 1fr;
	}
	.kompleksowa.blue-2 {
		background: transparent linear-gradient(210deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0%
			no-repeat padding-box;
	}
	.zaufanie__block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	.zaufanie__description {
		padding-right: 0;
	}
	section.foto-o4b::after {
		content: "";
		background: #000;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.foto-o4b__footer {
		background: transparent;
	}
	.footer__info a {
		color: #9daad5 !important;
	}
	.footer__bottom {
		flex-direction: column;
		gap: 32px;
	}
	.footer__top {
		margin-bottom: 16px;
	}
	.footer__top .footer__contact {
		align-items: flex-start;
	}
	.footer__top p {
		margin-top: 0;
	}
}
@media (max-width: 600px) {
	.relations__image img {
		max-height: 40px;
	}
}
@media (max-width: 480px) {
	.banner__decoration {
		display: none;
	}
	#e-administration .no-white-space {
		display: flex;
	}
	.pu-btn {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@media (max-width: 400px) {
	.relations__image img {
		max-height: 30px;
	}
}
