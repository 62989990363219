.sitemap__wrapper {
  display: flex;
  gap: 64px;
  max-width: 1710px;
  margin: 0 auto;
  padding: 84px 64px 240px;
}

.sitemap a {
  font-size: 30px;
  font-weight: 400;
}

.sitemap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
