.MuiOutlinedInput-notchedOutline {
	border: 0 !important;
	padding: 0 !important;
}

.MuiInputLabel-root.Mui-focused {
	color: var(--primary-color) !important;
	font-weight: 500 !important;
}

.MuiInputBase-input {
	font-family: var(--base-font-family) !important;
}

.MuiInputLabel-root {
	font-family: var(--base-font-family) !important;
}
