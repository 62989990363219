.youtube-article {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .youtube-full-width {
    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}

.youtube-modal {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .youtube-full-width {
    &__video {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 20px;
    }
  }
}
