/* GENERAL */
*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
	background: var(--html-body-background);
	font-family: var(--base-font-family);
	font-size: var(--base-font-size);
	font-weight: var(--base-font-weight);
	// line-height: var(--base-line-height);
	color: var(--html-body-color);
	-webkit-font-smoothing: antialiased;
}

// body {
//   margin-top: calc(5.625rem - 1rem);

//   &.lockBody {
//     &::after {
//       content: "";
//       position: fixed;
//       top: 0;
//       left: 0;
//       background: rgba(85, 85, 85, 0.5);
//       width: 100%;
//       height: 100%;
//       z-index: 1;
//     }
//   }
// }

a {
	color: var(--a-color);
	text-decoration: none;

	// &:hover {
	//   text-decoration: underline;
	// }
}

img {
	// max-width: 100%;
	height: auto;
}

// @media (max-width: 992px) {
//   html,
//   body {
//     font-size: 14px;
//   }
// }
