@import "./variables";

/* SCREEN READERS ONLY */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;

  // "skip to main content"
  &-focusable {
    &:focus,
    &:active {
      clip: auto !important;
      -webkit-clip-path: none !important;
      clip-path: none !important;
      height: auto !important;
      margin: auto !important;
      overflow: visible !important;
      width: auto !important;
      white-space: normal !important;
    }
  }
}

.completeHiding {
  display: none !important;
  visibility: hidden !important;
}

// .no-scroll {
//   padding-right: 15px;

//   .pu-header {
//     padding-right: 39px;

//     &__logo {
//       left: calc(50% - 7.5px);
//     }
//   }
// }

.search-shadow {
  &::after {
    background: rgba(0, 0, 0, 0.75);
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
  }

  .pu-search {
    z-index: 10001;
  }
}

.login-shadow {
  &::after {
    background: rgba(0, 0, 0, 0.75);
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.notifications-shadow {
  &::after {
    background: rgba(0, 0, 0, 0.75);
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.no-white-space {
  white-space: nowrap;
}

/* MARGIN */
.margin {
  &-top {
    &-16 {
      margin-top: 16px;
    }
    &-24 {
      margin-top: 24px;
    }
    &-32 {
      margin-top: 32px;
    }
    &-48 {
      margin-top: 48px;
    }
    &-64 {
      margin-top: 64px;
    }
  }

  &-right {
    &-16 {
      margin-right: 16px;
    }
    &-24 {
      margin-right: 24px;
    }
    &-32 {
      margin-right: 32px;
    }
    &-48 {
      margin-right: 48px;
    }
    &-64 {
      margin-right: 64px;
    }
  }

  &-bottom {
    &-16 {
      margin-bottom: 16px;
    }
    &-24 {
      margin-bottom: 24px;
    }
    &-32 {
      margin-bottom: 32px;
    }
    &-48 {
      margin-bottom: 48px;
    }
    &-64 {
      margin-bottom: 64px;
    }
  }

  &-left {
    &-16 {
      margin-left: 16px;
    }
    &-24 {
      margin-left: 24px;
    }
    &-32 {
      margin-left: 32px;
    }
    &-48 {
      margin-left: 48px;
    }
    &-64 {
      margin-left: 64px;
    }
  }
}

/* PADDING */
.padding {
  &-top {
    &-16 {
      padding-top: 16px;
    }
    &-24 {
      padding-top: 24px;
    }
    &-32 {
      padding-top: 32px;
    }
    &-48 {
      padding-top: 48px;
    }
    &-64 {
      padding-top: 64px;
    }
  }

  &-right {
    &-16 {
      padding-right: 16px;
    }
    &-24 {
      padding-right: 24px;
    }
    &-32 {
      padding-right: 32px;
    }
    &-48 {
      padding-right: 48px;
    }
    &-64 {
      padding-right: 64px;
    }
  }

  &-bottom {
    &-16 {
      padding-bottom: 16px;
    }
    &-24 {
      padding-bottom: 24px;
    }
    &-32 {
      padding-bottom: 32px;
    }
    &-48 {
      padding-bottom: 48px;
    }
    &-64 {
      padding-bottom: 64px;
    }
  }

  &-left {
    &-16 {
      padding-left: 16px;
    }
    &-24 {
      padding-left: 24px;
    }
    &-32 {
      padding-left: 32px;
    }
    &-48 {
      padding-left: 48px;
    }
    &-64 {
      padding-left: 64px;
    }
  }
}

/* HIDE */
.hide {
  &-desktop {
  }
  &-tablet {
    @media (max-width: 992px) {
      display: none;
    }
  }
  &-mobile {
  }
}

/* BOLD */
.bold {
  font-weight: 500;
}

/* BLUR */
.blur {
  &::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 52%,
      rgba(255, 255, 255, 1) 100%
    );
    background-repeat: no-repeat;
    height: 500px;
    width: 100%;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

/* COLORS */
.red {
  color: var(--red-color);
}

.green {
  color: var(--green-color);
}
