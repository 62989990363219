.pu-navigation {
	display: flex;
	align-items: center;

	&__logo-ue {
		margin-left: 24px;
		margin-top: 4px;
	}

	&__nav {
		position: fixed;
		top: 92px;
		left: 0;
		width: 100%;
		height: 100vh;
		padding: 24px;
		margin-top: 32px;
		background: var(--navigation-nav-background);
		z-index: 10000;
	}

	&__submenu {
		display: none;
	}

	&__wrapper {
		display: flex;
		max-width: 1710px;
		margin: 0 auto;
		width: 100%;
	}

	&__menu {
		display: flex;
		flex-direction: column;

		a {
			&.active {
				text-decoration: underline;
			}
		}

		&__item {
			background: transparent;
			display: flex;
			position: relative;
			width: 405px;
			padding: 16px 32px;
			margin-bottom: 10px;
			font-size: 1.875rem;
			color: var(--navigation-menu-item-color);
			text-decoration: none;
			cursor: pointer;
			border: 0;
			text-align: left;

			&:focus,
			&:hover {
				background-color: var(--navigation-menu-item-hover-background);
				font-weight: 700;
			}

			&.active {
				background-color: var(--navigation-menu-item-active-background);
				font-weight: 700;
			}

			&.separator {
				margin-bottom: 64px;
			}
		}

		&__separator {
			border-bottom: 1px solid var(--navigation-menu-separator-border-color);
			position: absolute;
			bottom: -32px;
			width: 100%;
			left: 0;
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 1;

		&__content {
			display: none;
			background-color: var(--navigation-content-background);
			border: 1px solid var(--navigation-content-border-color);
			padding: 20px;
			border-radius: 10px;
			margin-left: 16px;

			&.active {
				display: block;
			}
		}

		&__login {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 124px;

			&__description {
				color: var(--navigation-login-description-color);
				margin: 32px 0 64px;
			}

			&__title {
				font-size: 1.5rem;
			}

			&__image {
				margin-bottom: 64px;

				&__arrow {
					position: absolute;
					top: 32px;
					right: 0;
				}
			}
		}
	}

	&__button {
		font-size: 1.875rem;
		background: var(--navigation-button-background);
		color: var(--navigation-button-color);
		border: 0;
		font-weight: 700;
		padding: 4px 0;
		cursor: pointer;

		&__text {
			margin-left: 8px;
		}

		&__icon {
			display: inline-block;
			position: relative;
			top: 3px;
			transition-property: opacity, filter;
			transition-duration: 0.15s;
			transition-timing-function: linear;
			font: inherit;
			color: inherit;
			text-transform: none;
			background-color: transparent;
			border: 0;
			margin: 0;
			overflow: visible;

			&:hover {
				opacity: 0.7;
			}

			&.is-active:hover {
				opacity: 0.7;
			}

			&-box {
				width: 20px;
				height: 24px;
				display: inline-block;
				position: relative;
			}

			&-inner {
				display: block;
				top: 50%;
				margin-top: -2px;
			}
			&-inner,
			&-inner::before,
			&-inner::after {
				width: 20px;
				height: 2px;
				background-color: var(--navigation-button-icon-inner-background);
				border-radius: 4px;
				position: absolute;
				transition-property: transform;
				transition-duration: 0.15s;
				transition-timing-function: ease;
			}
			&-inner::before,
			&-inner::after {
				content: "";
				display: block;
			}
			&-inner::before {
				top: -8px;
			}
			&-inner::after {
				bottom: -8px;
			}

			&--squeeze {
				.pu-navigation {
					&__button {
						&__icon {
							&-inner {
								transition-duration: 0.075s;
								transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

								&::before {
									transition:
										top 0.075s 0.12s ease,
										opacity 0.075s ease;
								}

								&::after {
									transition:
										bottom 0.075s 0.12s ease,
										transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
								}
							}
						}
					}
				}

				&.is-active {
					.pu-navigation {
						&__button {
							&__icon {
								&-inner {
									transform: rotate(45deg);
									transition-delay: 0.12s;
									transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

									&::before {
										top: 0;
										opacity: 0;
										transition:
											top 0.075s ease,
											opacity 0.075s 0.12s ease;
									}
									&::after {
										bottom: 0;
										transform: rotate(-90deg);
										transition:
											bottom 0.075s ease,
											transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1100px) {
	.pu-navigation {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__nav {
			top: 28px;
		}

		&__submenu {
			display: block;
			border: 0;
			padding: 24px;
			background: transparent;
		}
	}
}

@media (max-width: 768px) {
	.pu-navigation {
		&__button {
			&__text {
				display: none;
			}
		}
	}
}
