.MuiAutocomplete-root .MuiOutlinedInput-root {
	background: #fff !important;
	border-radius: 50px !important;
	display: flex !important;
	flex-wrap: wrap !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
	background: #fafafa !important;
	font-weight: 500 !important;
	padding: 11px 16px !important;
}

.MuiAutocomplete-option.Mui-focused {
	background: #fafafa !important;
}

.MuiAutocomplete-paper {
	border-radius: 20px !important;
	box-shadow: var(--box-shadow) !important;
}

.MuiAutocomplete-listbox {
	padding: 0 !important;
}

.MuiAutocomplete-option:first-child {
	border-radius: 20px 20px 0 0 !important;
}

.MuiAutocomplete-option:last-child {
	border-radius: 0 0 20px 20px !important;
}

.MuiAutocomplete-paper {
	font-family: var(--base-font-family) !important;
}
