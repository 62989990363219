div#__next {
  height: 100%;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 24px;
  text-align: center;

  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
  }

  &__number {
    font-size: 8rem;
    color: var(--not-found-number-color);
    font-weight: 400;
    margin-bottom: 64px;
  }

  &__title {
    font-weight: 500;
    text-align: center;
    font-size: 3rem;
    line-height: 2.5rem;
    margin-bottom: 24px;
    color: var(--not-found-title-color);
  }

  &__description {
    margin-bottom: 32px;
  }

  &__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 0 64px;

    &__content {
      display: none;
      margin-bottom: 32px;

      &.active {
        display: block;
      }
    }
  }

  &__decorative {
    &__one {
      position: absolute;
      left: 0;
      top: 0;
    }

    &__two {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  table {
    border-collapse: collapse;
    text-align: left;
    font-size: 0.875rem;

    th {
      font-weight: 400;
      padding: 12px;
    }

    td {
      padding: 12px;
    }

    tr {
      &:nth-child(odd) {
        th {
          background: var(--not-found-table-th-background);
          border-radius: 20px 0 0 20px;
        }

        td {
          background: var(--not-found-table-td-background);
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .not-found {
    &__number {
      font-size: 6rem;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 16px;
    }

    &__error {
      margin-bottom: 24px;
    }

    &__decorative {
      display: none;
    }
  }
}
