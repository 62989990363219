.pu-highcontrast {
	&:root {
		--primary-color: #ff0;
		--tertiary-color: #1a73de;
		--secondary-color: #536390;
		--font-color: #fff;
		--white-color: #ffffff;
		--dark-color: #000000;
		--lightdark-color: #fafafa;
		--mediumdark-color: #e1e1e1;
		--largedark-color: #555555;
		--darkgray-color: #707070;

		--box-shadow: 0px 3px 50px #2222220d;

		--page-width: 1710px;

		--transition-time-normal: 250ms;

		// NEW VARIABLES
		--html-body-background: #000;
		--html-body-color: #fff;

		--a-color: #ff0;

		--accordion-primary-item-border-color: #e1e1e1;
		--accordion-primary-item-button-background: var(--primary-color);
		--accordion-primary-title-color: #ff0;

		--appointment-background: #000;
		--appointment-color: #fff;
		--appointment-main-color: #fff;
		--appointment-border-color: #fff;
		--appointment-number-color: #fff;

		--banner-background: #000;
		--banner-background-light: #000;
		--banner-color: #fff;
		--banner-color-light: var(--font-color);
		--banner-border-color: #fff;

		--breadcrumb-light-color: #fff;
		--breadcrumb-light-a-color: #ff0;
		--breadcrumb-light-li-border-color: #fff;
		--breadcrumb-dark-color: #fff;
		--breadcrumb-dark-a-color: #ff0;
		--breadcrumb-dark-li-border-color: #fff;
		--breadcrumb-li-border-color: var(--primary-color);

		--btn-primary-background: transparent linear-gradient(94deg, rgb(149, 149, 0) 0%, #ff0 100%) 0%
			0% no-repeat padding-box;
		--btn-primary-color: #000;

		--btn-navigate-back-color: #ff0;

		--btn-secondary-color: #ff0;
		--btn-secondary-border-color: #ff0;

		--btn-clear-color: #ff0;

		--btn-outline-color: #ff0;
		--btn-outline-border-color: #ff0;

		--btn-underline-color: #ff0;

		--btn-link-color: #ff0;

		--btn-icon-color: #ff0;

		--btn-back-color: #999;
		--btn-back-hover-box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);

		--card-background: #000;
		--card-border-color: #fff;
		--card-box-shadow: 0px 3px 50px #2222220d;

		--cases-border-color: #fff;

		--categories-border-color: #fff;
		--categories-blue-background: #000;
		--categories-blue-color: #fff;
		--categories-blue-title-h2: #fff;
		--categories-blue-more-color: #ff0;

		--checkbox-container-border-color: #fff;
		--checkbox-checkmark-background: #000;
		--checkbox-checkmark-border-color: #ff0;
		--checkbox-checkmark-checked-background: #ff0;
		--checkbox-checkmark-checked-color: #000;
		--checkbox-container-focus-box-shadow: 0 0 0 2px rgba(26, 115, 222, 0.4);

		--contact-phone-a-color: #ff0;
		--contact-email-a-color: #ff0;
		--contact-container-background: #00;

		--cookie-background: #000;
		--cookie-color: var(--font-color);
		--cookie-border-color: #fff;

		--dropdown-box-shadow: var(--box-shadow);
		--dropdown-content-background: #000;
		--dropdown-option-background: #000;
		--dropdown-option-color: #ff0;
		--dropdown-option-border-color: #ff0;
		--dropdown-option-highlighted-background: #ff0;
		--dropdown-option-highlighted-color: #000;
		--dropdown-option-hover-background: #ff0;
		--dropdown-option-focus-box-shadow: 0 0 0 2px rgba(26, 115, 222, 0.4);
		--dropdown-aria-expanded-true-header-wcag-element-background: #000;

		--empty-state-background: #000;
		--empty-state-border-color: #fff;

		--family-border-color: #fff;

		--footer-background: #000;
		--footer-color: #fff;
		--footer-column-left-border-color: #fff;
		--footer-nav-link-color: #ff0;
		--footer-version-color: #fff;
		--footer-author-color: #fff;

		--header-background: #000;
		--header-border-color: #fff;
		--header-mobile-background: #000;
		--header-mobile-button-color: #ff0;
		--header-mobile-button-border-color: #ff0;
		--header-mobile-close-background-color: #ff0;
		--header-wcag-elements-background: #000;
		--header-wcag-elements-border-color: #fff;
		--header-wcag-element-color: #ff0;
		--header-wcag-font-button-color: #ff0;

		--hambuger-icon-line-background: #ff0;

		--hero-border-color: #fff;
		--hero-title-h1-color: #fff;
		--hero-search-title-color: #fff;
		--hero-column-background-background: #000;
		--hero-sidebar-background: #000;
		--hero-sidebar-border-color: #fff;
		--hero-sidebar-another-background: #000;
		--hero-scroll-action-background: #000;
		--hero-scroll-action-border-color: #fff;

		--highlight-color: var(--primary-color);

		--login-dialog-wrapper-border-color: #fff;
		--login-dialog-column-left-background: #000;
		--login-dialog-column-left-border-right: #fff;
		--login-dialog-benefits-image-background: #e3ecf8;

		--modal-container-background: #000;

		--navigation-nav-background: #000;
		--navigation-menu-item-color: #ff0;
		--navigation-menu-item-hover-background: #000;
		--navigation-menu-item-active-background: #000;
		--navigation-menu-separator-border-color: #fff;
		--navigation-content-background: #000;
		--navigation-content-border-color: #fff;
		--navigation-login-description-color: #fff;
		--navigation-button-background: transparent;
		--navigation-button-color: #ff0;
		--navigation-button-icon-inner-background: #ff0;

		--not-found-number-color: #fff;
		--not-found-title-color: #fff;
		--not-found-table-th-background: #000;
		--not-found-table-td-background: #000;

		--search-results-background: #000;
		--search-results-border-color: #fff;
		--search-results-item-color: #ff0;

		--search-input-background: #000;
		--search-input-color: var(--font-color);
		--search-input-placeholder-color: #ff0;
		--search-input-border-color: #fff;

		--search-modal-background: #000;
		--search-modal-result-color: var(--font-color);
		--search-modal-result-border-color: #fff;
		--search-modal-result-title-color: #ff0;
		--search-modal-close-action-background-color: #ff0;

		--service-catalog-border-color: #fff;
		--service-catalog-service-title: #ff0;

		--tabs-tab-color: #fff;
		--tabs-tab-active-color: var(--primary-color);
		--tabs-tab-active-border-color: var(--primary-color);
		--tabs-panel-border-color: var(--mediumdark-color);

		--tags-tag-background: #000;
		--tags-tag-color: #ff0;
		--tags-tag-border-color: #ff0;
	}

	.pu-service-catalog {
		&__service {
			&__image {
				img {
					filter: invert(1);
				}
			}
		}
	}

	.pu-hero {
		&__decorative {
			display: none;
		}

		&__scroll {
			&__action {
				img {
					filter: invert(1);
				}
			}
		}
	}

	.pu-header {
		&__search {
			filter: invert(1);
		}

		&__logo {
			img {
				position: relative;
				top: 8px;
			}
		}

		&__wcag {
			&__element {
				&__image {
					img {
						filter: invert(1);
					}
				}
			}
		}

		&__logout {
			background: #000;
			border: 1px solid #fff;
			color: #ff0;
		}
	}

	.not-found {
		&__decorative {
			display: none;
		}
	}

	.pu-banner {
		&__decorative {
			display: none;
		}
	}

	.pu-categories {
		&__decorative {
			display: none;
		}

		&__more {
			img {
				display: none;
			}
		}
	}

	.pu-family {
		&__decorative {
			display: none;
		}
	}

	.pu-accordion {
		&--primary {
			.accordion {
				&__icon {
					img {
						filter: invert(1);
					}
				}
			}
		}
	}

	.pu-contact {
		img {
			filter: invert(1);
		}
	}

	.pu-dropdown {
		&__option {
			&__check {
				img {
					filter: invert(1);
				}
			}
		}
	}

	.pu-search {
		&__input {
			color: #fff;

			&__icon {
				img {
					filter: invert(1);
				}
			}
		}
	}

	.dropdown {
		&-button {
			background: #000;
			color: #ff0;
		}

		&-menu {
			background: #000;
			border: 1px solid;
			border-top: 0;
		}
	}
}
