.pu-header {
  height: 124px;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: var(--header-background);
  border-bottom: 1px solid var(--header-border-color);
  z-index: 10000;

  @include sectionPadding();

  &__login {
    .dropdown {
      &-menu {
        min-width: 750px;
        padding: 24px 16px;
        bottom: -282px;
      }

      &-container {
        p {
          width: 75%;
        }
      }
    }

    .dropdown {
      &__top {
        padding: 24px 32px;
        background: #fafafa;
        border-radius: 20px;
      }

      &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
      }
    }

    .pu-header {
      &__logout {
        background: transparent;
        padding: 32px 38px;
        margin-top: 0;
      }
    }
  }

  &__logout {
    background: #fafafa;
    border-radius: 20px;
    border-color: transparent;
    padding: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    cursor: pointer;

    img {
      margin-right: 16px;
    }
  }

  &__mobile {
    display: none;
  }

  &__menu {
    // font-size: 1.875rem;
    // font-weight: 700;
    display: flex;
    align-items: center;
  }

  &__search {
    margin-right: 32px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: var(--page-width);
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  &__column {
    display: flex;
  }

  &__logo {
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__notifications {
    margin-right: 44px;

    .dropdown {
      &-menu {
        bottom: -158px;
      }

      &-container {
        margin-left: 16px;
      }
    }
  }

  &__wcag {
    width: 100%;

    &__wrapper {
      display: flex;
      justify-content: flex-end;
      max-width: var(--page-width);
      margin: 0 auto;
    }

    &__elements {
      display: flex;
      background: var(--header-wcag-elements-background);
      padding: 4px 32px;
      border-radius: 0 0 10px 10px;
      border: 1px solid var(--header-wcag-elements-border-color);
      border-top: 0;
    }

    &__element {
      display: flex;
      align-items: center;
      margin-right: 48px;
      background: transparent;
      border: 0;
      color: var(--header-wcag-element-color);
      cursor: pointer;

      &__image {
        position: relative;
        top: 2px;
        left: -2px;
        margin-left: 8px;
      }

      &__activeLanguage {
        text-transform: uppercase;
      }

      // &:last-of-type {
      //   margin-right: 0;
      // }
    }

    &__font {
      display: flex;
      align-items: center;
      margin-right: 48px;

      &__action {
        display: flex;
      }

      &__button {
        padding: 0 8px;
        margin: 0 2px;
        background: transparent;
        border: 0;
        color: var(--header-wcag-font-button-color);
        cursor: pointer;

        &.normal {
          font-size: 14px;
          position: relative;
          top: 1px;
        }

        &.bigger {
          font-size: 18px;
        }

        &.active {
          font-weight: 500;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }

  &__panel {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 8px 0 0;

  .line {
    width: 100%;
    height: 2px;
    background-color: var(--hambuger-icon-line-background);
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1100px) {
  .pu-header {
    height: 60px;
    padding-right: 0;

    &__logo {
      top: 14px;
    }

    &__menu {
      width: 100%;
    }

    &__wcag {
      display: none;
    }

    &__actions {
      display: none;
      justify-content: center;
      padding-bottom: 0;
    }

    &__mobile {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      background: var(--header-mobile-background);
      z-index: 10000;
      width: 100%;
      height: 100%;

      &.active {
        display: block;
      }

      &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        padding: 84px 16px 16px;
        font-size: 0.875rem;
      }

      &__close {
        position: absolute;
        top: 24px;
        right: 16px;
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      &__close::before,
      &__close::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        background-color: var(--header-mobile-close-background-color);
      }

      &__close::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &__close::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &__button img {
        max-height: 24px;
      }
    }

    .pu-header__wcag__element,
    .pu-header__wcag__element,
    .pu-header__wcag__font,
    .pu-header__mobile__button {
      height: 100px;
      border: 1px solid var(--header-mobile-button-border-color);
      box-shadow: 0px 3px 50px #2222220d;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      margin: 0;
      padding: 8px;
      width: 100%;
      color: var(--header-mobile-button-color);
    }
  }
}

@media (max-width: 768px) {
  .pu-header {
    &__login {
      display: none;
    }

    &__search {
      display: none;
    }
  }
}
