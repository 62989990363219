:root {
	--primary-color: #1a73de;
	--tertiary-color: #1a73de;
	--secondary-color: #536390;
	--font-color: #222222;
	--white-color: #ffffff;
	--dark-color: #000000;
	--lightdark-color: #fafafa;
	--mediumdark-color: #e1e1e1;
	--largedark-color: #555555;
	--darkgray-color: #707070;

	--box-shadow: 0px 3px 50px #2222220d;

	--page-width: 1710px;

	--transition-time-normal: 250ms;

	// NEW VARIABLES
	--html-body-background: #fff;
	--html-body-color: var(--base-font-color);

	--a-color: var(--primary-color);

	--accordion-primary-item-border-color: #e1e1e1;
	--accordion-primary-item-button-background: var(--primary-color);
	--accordion-primary-title-color: var(--font-color);

	--appointment-background: transparent
		linear-gradient(260deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0% no-repeat padding-box;
	--appointment-color: #fff;
	--appointment-main-color: #fff;
	--appointment-border-color: #fff;
	--appointment-number-color: #fff;

	--banner-background: transparent linear-gradient(124deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0%
		0% no-repeat padding-box;
	--banner-background-light: #fafafa;
	--banner-color: #fff;
	--banner-color-light: var(--font-color);
	--banner-border-color: #fff;

	--breadcrumb-light-color: #fff;
	--breadcrumb-light-a-color: #fff;
	--breadcrumb-light-li-border-color: #fff;
	--breadcrumb-dark-color: #222;
	--breadcrumb-dark-a-color: #222;
	--breadcrumb-dark-li-border-color: #222;
	--breadcrumb-li-border-color: var(--primary-color);

	--btn-primary-background: transparent
		linear-gradient(94deg, #0d309d 0%, var(--primary-color) 100%) 0% 0% no-repeat padding-box;
	--btn-primary-color: var(--white-color);

	--btn-navigate-back-color: var(--primary-color);

	--btn-secondary-color: var(--white-color);
	--btn-secondary-border-color: var(--white-color);

	--btn-clear-color: var(--primary-color);

	--btn-outline-color: var(--primary-color);
	--btn-outline-border-color: var(--primary-color);

	--btn-underline-color: var(--primary-color);

	--btn-link-color: var(--primary-color);

	--btn-icon-color: var(--primary-color);

	--btn-back-color: #999;
	--btn-back-hover-box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);

	--card-background: #fff;
	--card-border-color: #fff;
	--card-box-shadow: 0px 3px 50px #2222220d;

	--cases-border-color: #fff;

	--categories-border-color: #fff;
	--categories-blue-background: transparent
		linear-gradient(250deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0% no-repeat padding-box;
	--categories-blue-color: #fff;
	--categories-blue-title-h2: #fff;
	--categories-blue-more-color: #fff;

	--checkbox-container-border-color: #0000001a;
	--checkbox-checkmark-background: #fff;
	--checkbox-checkmark-border-color: var(--primary-color);
	--checkbox-checkmark-checked-background: var(--primary-color);
	--checkbox-checkmark-checked-color: #fff;
	--checkbox-container-focus-box-shadow: 0 0 0 2px rgba(26, 115, 222, 0.4);

	--contact-phone-a-color: var(--font-color);
	--contact-email-a-color: var(--font-color);
	--contact-container-background: #fafafa;

	--cookie-background: #fff;
	--cookie-color: var(--font-color);
	--cookie-border-color: transparent;

	--dropdown-box-shadow: var(--box-shadow);
	--dropdown-content-background: #fff;
	--dropdown-option-background: #fff;
	--dropdown-option-color: #000;
	--dropdown-option-border-color: #fff;
	--dropdown-option-highlighted-background: #fff;
	--dropdown-option-highlighted-color: #222;
	--dropdown-option-hover-background: #f0f0f0;
	--dropdown-option-focus-box-shadow: 0 0 0 2px rgba(26, 115, 222, 0.4);
	--dropdown-aria-expanded-true-header-wcag-element-background: #fff;

	--empty-state-background: #fafafa;
	--empty-state-border-color: transparent;

	--family-border-color: #fff;

	--footer-background: var(--primary-color);
	--footer-color: #fff;
	--footer-column-left-border-color: rgba(255, 255, 255, 0.5);
	--footer-nav-link-color: #fff;
	--footer-version-color: color: rgba(255, 255, 255, 0.5);
	--footer-author-color: color: rgba(255, 255, 255, 0.5);

	--header-background: #fff;
	--header-border-color: #fff;
	--header-mobile-background: #fff;
	--header-mobile-button-color: #222;
	--header-mobile-button-border-color: transparent;
	--header-mobile-close-background-color: #222;
	--header-wcag-elements-background: #fafafa;
	--header-wcag-elements-border-color: #fff;
	--header-wcag-element-color: #000;
	--header-wcag-font-button-color: #000;

	--hambuger-icon-line-background: #000;

	--hero-border-color: #fff;
	--hero-title-h1-color: #fff;
	--hero-search-title-color: #fff;
	--hero-column-background-background: transparent
		linear-gradient(124deg, #0c2b97 0%, #1369db 72%, #1473e6 100%) 0% 0% no-repeat padding-box;
	--hero-sidebar-background: #fff;
	--hero-sidebar-border-color: #fff;
	--hero-sidebar-another-background: #fafafa;
	--hero-scroll-action-background: #fff;
	--hero-scroll-action-border-color: #fff;

	--highlight-color: var(--primary-color);

	--login-dialog-wrapper-border-color: #fff;
	--login-dialog-column-left-background: #fff;
	--login-dialog-column-left-border-color: #fff;
	--login-dialog-benefits-image-background: #e3ecf8;

	--modal-container-background: #fff;

	--navigation-nav-background: #fff;
	--navigation-menu-item-color: var(--font-color);
	--navigation-menu-item-hover-background: var(--lightdark-color);
	--navigation-menu-item-active-background: var(--lightdark-color);
	--navigation-menu-separator-border-color: #e1e1e1;
	--navigation-content-background: var(--lightdark-color);
	--navigation-content-border-color: var(--lightdark-color);
	--navigation-login-description-color: #555555;
	--navigation-button-background: transparent;
	--navigation-button-color: #000;
	--navigation-button-icon-inner-background: #000;

	--not-found-number-color: #e1e1e1;
	--not-found-title-color: #222;
	--not-found-table-th-background: #fafafa;
	--not-found-table-td-background: #fafafa;

	--search-results-background: #fff;
	--search-results-border-color: #fff;
	--search-results-item-color: var(--primary-color);

	--search-input-background: #fff;
	--search-input-color: var(--font-color);
	--search-input-placeholder-color: #555;
	--search-input-border-color: #fff;

	--search-modal-background: #fafafa;
	--search-modal-result-color: var(--font-color);
	--search-modal-result-border-color: #e1e1e1;
	--search-modal-result-title-color: var(--font-color);
	--search-modal-close-action-background-color: #222;

	--service-catalog-border-color: #fff;
	--service-catalog-service-title: var(--primary-color);

	--tabs-tab-color: #555;
	--tabs-tab-active-color: var(--primary-color);
	--tabs-tab-active-border-color: var(--primary-color);
	--tabs-panel-border-color: var(--mediumdark-color);

	--tags-tag-background: var(--lightdark-color);
	--tags-tag-color: var(--font-color);
	--tags-tag-border-color: var(--lightdark-color);
}

@mixin sectionPadding {
	padding-left: 24px;
	padding-right: 24px;

	@media (max-width: 992px) {
		padding-left: 16px;
		padding-right: 16px;
	}
}
